import React from "react";
import Navbar from "./navbar";

const App: React.FC = () => {
  return (
    <div>
      <Navbar />
      <main className="mt-8">
        <h1 className="text-center text-2xl">Welcome Govarthan bro!</h1>
      </main>
    </div>
  );
};

export default App;