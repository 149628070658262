import React from "react";

const Navbar: React.FC = () => {
  return (
    <nav className="bg-black text-white py-4">
      <div className="container mx-auto text-center">
        <h1 className="text-xl font-bold tracking-wide">OUR FIRST PROJECT</h1>
      </div>
    </nav>
  );
};

export default Navbar;
